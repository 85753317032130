import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="How far do you need to go?"
    options={[
      {
        label: "I need advanced design features",
        link: "/app/digital-product-design/hi-fi/advanced-features/"
      },
      {
        label: "I just need the basics to quickly design an idea",
        link: "/app/tool/uizard/"
      }
  ]}/>
)

export default Picker;


